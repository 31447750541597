import { useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import { Header } from '@weareopenr/component-library';
import AdminAppSidebar from 'src/components/AdminAppSidebar';
import styles from './Layout.module.scss';
import { Dashboard } from '../../pages/Dashboard/Dashboard';
import { Brand } from 'src/pages/Brand/Brand';

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const { authState, oktaAuth } = useOktaAuth();

  const handleDrawerToggle = () => setIsSidebarOpen((prev: boolean) => !prev);
  const closeSideBar = () => setIsSidebarOpen(false);
  const handleLogout = async () => await oktaAuth.signOut();

  return (
    <div data-testid="layout-root" className={styles['layout']}>
      <AdminAppSidebar
        open={isSidebarOpen}
        appName="Admin"
        currentPath={pathname}
        onSidebarClick={closeSideBar}
      />
      <div className={styles['right-container']}>
        <Header
          name={authState?.idToken?.claims?.name ?? ''}
          userRole="admin"
          switchAppName="Hub"
          switchLink={window.location.origin.replace('admin', 'bof')}
          isAdminButtonEnabled
          onDrawerToggle={handleDrawerToggle}
          onLogout={handleLogout}
        />
        <div className={styles['content']}>
          <div className={styles['inner-content']}>
            <Switch>
              <SecureRoute exact path="/" component={Dashboard} />
              <SecureRoute exact path="/brands/:brandId" component={Brand} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
